import axios from '@/axios.js'

export default class DatatableService
{
    #baseUrl = null
    #commonQueryFilters = []
    #perPage = 30

    constructor(baseUrl, commonQueryFilters = [], perPage = 30) {
        this.#baseUrl = baseUrl
        this.#commonQueryFilters = commonQueryFilters
        this.#perPage = perPage
    }

    async fetchElements(filters, page, sort = {sort_by: 'order', sort_type: 'asc'}) 
    {
        try {
            let params = {
                params: {
                    ...{ q: this.mapFiltersWithCommon(filters) },
                    ...{ page: page, per_page: this.#perPage },
                    ...sort
                }
            }
            const response = await axios.editorapi.get(this.#baseUrl, params)
            return response.data.object
        } catch (error) {
            throw new Error(error)
        }
    }

    mapFiltersWithCommon(filters)
    {
        let mixedFilters = { ...this.#commonQueryFilters, ...filters }
        return Object.keys(mixedFilters).map(key => {
            return key + ':' + mixedFilters[key]
        }).join(',')
    }

    async sortElements(sortedUuids)
    {
        try {
            let payload = {
                ...this.#commonQueryFilters,
                ...{ sorted_items: sortedUuids }
            }
            const response = await axios.editorapi.patch(this.#baseUrl, payload)
            return response.data.object
        } catch (error) {
            throw new Error(error)
        }
    }

    async deleteElement(uuid)
    {
        let deleteUrl = `${this.#baseUrl}/${uuid}`
        try {
            const response = await axios.editorapi.delete(deleteUrl)
            return response.data
        } catch (error) {
            throw new Error(error)
        }
    }
}