var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("DatatablePageLayout", {
    scopedSlots: _vm._u([
      {
        key: "actionButtons",
        fn: function() {
          return [
            _vm.actions.includes("create")
              ? _c(
                  "vs-button",
                  {
                    staticClass: "rounded-lg xs:w-full sm:w-auto",
                    attrs: { to: { name: _vm.routeConfigs.prefix + "-create" } }
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("shared.add")) + " ")]
                )
              : _vm._e()
          ]
        },
        proxy: true
      },
      {
        key: "datatable",
        fn: function() {
          return [
            _c("Datatable", {
              attrs: {
                config: _vm.datatableConfig,
                elements: _vm.zones,
                stripe: true,
                draggable: _vm.draggable,
                sst: true,
                pagination: _vm.draggable ? null : _vm.pagination
              },
              on: { action: _vm.action, filter: _vm.filter, sort: _vm.sort }
            })
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }