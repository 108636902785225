import axios from '@/axios.js'

export default class ElementService
{
    #baseUrl = null


    constructor(baseUrl) {
        this.#baseUrl = baseUrl
    }

    async fetchElement(uuid)
    {
        const response = await axios.editorapi.get(`${this.#baseUrl}/${uuid}`)
        return response.data.object
    }

    async createElement(uuid, payload)
    {
        const response = await axios.editorapi.post(`${this.#baseUrl}/${uuid}`, payload)
        return response.data
    }

    async editElement(uuid, payload)
    {
        const response = await axios.editorapi.patch(`${this.#baseUrl}/${uuid}`, payload)
        return response.data
    }
}